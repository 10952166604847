import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import AppBar from './ProductAppBar';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "0px",
    paddingRight: "0px",
    overflowX: "hidden",
    overflowY: 'auto',
    height: 'calc(100vh - 77px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 1px)',
    }
    // paddingLeft:" (props) => (props.removeHorizontalPadding ? 0 : null)",
    // paddingRight:" (props) => (props.removeHorizontalPadding ? 0 : null)",
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: (props) => (props.includeTopMargin ? theme.spacing(4) : 0),
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginTop: (props) =>
    //     props.showMobileAppBar && props.includeTopMargin ? theme.spacing(2) : 0,
    //   paddingLeft: 0,
    //   paddingRight: 0,
    // },
  },
}));

const ProductHoc = ({
  children,
  t,
  showMobileAppBar,
  includeTopMargin,
  removeHorizontalPadding,
  title,
}) => {
  const classes = useStyles({
    showMobileAppBar,
    includeTopMargin,
    removeHorizontalPadding,
  });
  const auth = useSelector((state) => state.auth);
  const [progessClick, setProgessClick] = useState(false)

  return (
    <div>
      <AppBar
        t={t}
        showMobileAppBar={showMobileAppBar}
        auth={auth}
        title={title}
        setProgessClick={setProgessClick}
      />
      <Container maxWidth="xl" className={classes.container}>
        {setProgessClick}
        {children}
      </Container>
    </div>
  );
};

ProductHoc.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  t: PropTypes.func.isRequired,
  showMobileAppBar: PropTypes.bool,
  includeTopMargin: PropTypes.bool,
  removeHorizontalPadding: PropTypes.bool,
  title: PropTypes.string,
};

ProductHoc.defaultProps = {
  showMobileAppBar: true,
  includeTopMargin: true,
  removeHorizontalPadding: false,
  title: 'PTE Benchmark',
};

export default ProductHoc;
