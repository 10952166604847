import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { useRouter } from 'next/router';

import {
  markAsReadNotificationAction,
  markAsReadNotificationsAction,
} from 'core/lib/adapters/redux/actions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 60,
    zIndex: 20,
    width: 330,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 400,
    borderRadius: 3,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
  },
  ListItem: {
    '&:hover': {
      backgroundColor: '#f3f5f9',
    },
  },
  primary: {
    fontSize: '14px',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonReadAll: {
    width: '100%',
    outline: 'none',
    border: 'none',
    marginTop: 0,
    height: 40,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  emptyNotifications: {
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
}));

export default function NotificationsList({ showList = false }) {
  const classes = useStyles();
  // notifications Redux state
  const dispatch = useDispatch();
  const unreadNotifications = useSelector(
    (state) => state.notification.unreadNotifications,
    shallowEqual,
  );
  const router = useRouter();
  const [sortedUnreadNotifications, setSortedUnreadNotifications] = useState(
    [],
  );

  const readNotificationHandler = (notif) => {
    dispatch(markAsReadNotificationAction(notif.id));
    switch (notif.type) {
      case 'new-ticket-message':
        router.push(
          '/support/ticket/[id]',
          `/support/ticket/${notif?.data?.ticket_id}`,
        );
        break;
      default:
        router.push('/notifications');
        break;
    }
  };

  useEffect(() => {
    setSortedUnreadNotifications(
      unreadNotifications?.data?.sort((a, b) =>
        a.created_at < b.created_at ? 1 : -1,
      ),
    );
  }, [unreadNotifications]);

  const dateHumanReadable = (date) => {
    return moment(date).fromNow();
  };

  return (
    <div
      className={classes.container}
      style={{
        border: `${showList ? '1px solid #DDDDDD' : 'none'}`,
      }}>
      {showList && (
        <>
          <List className={classes.root}>
            {sortedUnreadNotifications?.map((notif) => (
              <ListItem key={notif.id} className={classes.ListItem}>
                <ListItemAvatar>
                  <Avatar>
                    <NotificationsActiveIcon style={{ color: '#295e9b' }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.primary}
                  primary={notif.data.message}
                  secondary={dateHumanReadable(notif.created_at)}
                  onClick={() => readNotificationHandler(notif)}
                />
              </ListItem>
            ))}
            {sortedUnreadNotifications?.length === 0 && (
              <div className={classes.emptyNotifications}>
                There is no unread notification
              </div>
            )}
          </List>
          {sortedUnreadNotifications?.length !== 0 && (
            <button
              className={classes.buttonReadAll}
              type="button"
              onClick={() => {
                dispatch(markAsReadNotificationsAction());
              }}>
              Mark all as read
            </button>
          )}
        </>
      )}
    </div>
  );
}
