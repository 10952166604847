import * as React from 'react'

function SvgHelp(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10 17a1 1 0 100-2 1 1 0 000 2zM10 14a1 1 0 01-1-1v-2a1 1 0 011-1 2 2 0 10-2-2 1 1 0 11-2 0 4 4 0 115 3.87V13a1 1 0 01-1 1z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20h9c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10v9a1 1 0 001 1zm9-2H2v-8a8 8 0 118 8z"
        fill={props.color}
      />
      <path
        d="M23 24h-9a10 10 0 01-8.32-4.44A1 1 0 016 18.17a1 1 0 011.39.27A8 8 0 0014 22h8v-8a8 8 0 00-4.4-7.14 1.002 1.002 0 11.9-1.79A10 10 0 0124 14v9a1 1 0 01-1 1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgHelp
