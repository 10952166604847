import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Hidden, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
//components
import { logoutAction } from 'core/lib/adapters/redux/actions/authActions';
import { showProgress } from 'core/lib/adapters/redux/actions/dashboard';
import CheckingIcon from 'components/Icons/gen/checking';
import ContactUsIcon from 'components/Icons/gen/ContactUs';
import HelpIcon from 'components/Icons/gen/Help';
import FeedbackIcon from 'components/Icons/gen/Feedback';
import home from "../../assets/icons/home.svg"
import practice from "../../assets/icons/practice.svg"
import HeaderMockTest from '../../assets/icons/HeaderMockTest.svg'
import pteCourse from '../../assets/icons/pteCourse.svg'
import addOn from '../../assets/icons/addOn.svg'
import supportIcon from '../../assets/icons/support.svg'
import speakingIcon from '../../assets/icons/speakingIcon.svg'
import writingIcon from "../../assets/icons/writingIcon.svg"
import readingIcon from "../../assets/icons/readingIcon.svg"
import listeningIcon from "../../assets/icons/listeningIcon.svg"
import study_plan from '../../assets/icons/study_plan.svg'
import smart_advisor from "../../assets/icons/smart_advisor.svg"
import analytics from "../../assets/icons/analytics.svg"
import video_tour from "../../assets/icons/video_tour.svg"
import logoutIcon from "../../assets/icons/logout.svg"

// >>SIDEMENU 
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    sidebar: {
        borderRadius: 0,
        background: '#fff',
        padding: theme.spacing(2),
        paddingRight: 0,
        borderRight: '1px solid #eee',
        height: '100%',
    },
    listItemIcon: {
        minWidth: theme.spacing(5),
    },
    listItemText: {
        color: '#000',
        fontWeight: 600,
        fontSize: 16,
    },
    listItemTextActive: {
        color: theme.palette.primary.main,
    },
    listItem: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: '8px',
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(1.2),
            paddingRight: theme.spacing(1.2),
        },
        [theme.breakpoints.down('md')]: {
            borderBottom: '2px solid #eee'
        }
    },
    listItemActive: {
        borderRadius: '8px',
        background: '#e7eef4',
        // borderLeft: `3px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    },
    contentContainer: {
        height: 'calc(100vh - 77px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('lg')]: {
            height: 'calc(100vh - 64px)',
            // padding: theme.spacing(2),
            // paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 56px)',
            // paddingLeft: theme.spacing(1),
            // padding: theme.spacing(1),
            // paddingRight: theme.spacing(3),
        },
        marginBottom: theme.spacing(3),
        display: 'block',
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        height: 'calc(100vh - 270px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(0),
            overflowY: 'unset',
        }
    },
    avatarContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    avatar: {
        marginRight: theme.spacing(2),
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',
    },
    avatarText: {
        color: '#000',
    },
    sidebarLink: {
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    nested: {
        paddingLeft: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(6),
            borderBottom: '2px solid #eee'
        }
    },
}));

const CustomLink = ({ type, children, ...props }) => {
    const classes = useStyles();
    return type === 'Link' ? (
        <Link href={props.href} key={props.key}>
            <a className={classes.sidebarLink}>{children}</a>
        </Link>
    ) : (
        <div key={props.key}>{children}</div>
    );
};

const CommanMenuData = ({ toggleDrawer, t }) => {
    const router = useRouter();
    const [openPractice, setOpenPractice] = useState(false);
    const [openSupport, setOpenSupport] = useState(false);
    const [openAddOn, setOpenAddOn] = useState(false);
    const classes = useStyles();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const dashboardSidebar = [
        {
            title: t('dashboard'),
            icon: home,
            href: '/dashboard',
            isDashboard: true,
            childs: [],
        },
        {
            title: t('practice'),
            icon: practice,
            href: '/practice',
            childs: [
                { title: 'Speaking', icon: speakingIcon, href: 'Speaking' },
                { title: 'Writing', icon: writingIcon, href: 'Writing' },
                { title: 'Reading', icon: readingIcon, href: 'Reading' },
                { title: 'Listening', icon: listeningIcon, href: 'Listening' },
            ],
        },
        {
            title: t('mockTests'), icon: HeaderMockTest, href: '/mock', childs: []
        },
        { title: t('pteCourse'), icon: pteCourse, href: '/pte-course', childs: [] },
        { title: 'Analytics', icon: analytics, href: '/analytics', childs: [] },
        // {title: t('bookAnExpert'), icon: UserIcon, href: '/expert', childs: []},
        // {
        //   title: t('pteTutorial'),
        //   icon: WritingIcon,
        //   href: '/pte-tutorial',
        //   childs: [],
        // },
        // {
        //     title: t('addOn'),
        //     icon: addOn,
        //     href: '',
        //     childs: [
        //         { title: 'Study Plan', icon: study_plan, href: 'Speaking' },
        //         { title: 'Video Tour', icon: video_tour, href: 'Writing' },
        //         { title: 'Analytics', icon: analytics, href: 'analytics' },
        //         { title: 'Smart Advisor', icon: smart_advisor, href: 'Listening' },
        //     ],
        // },
        // { title: t('analytics'), icon: addOn, href: '/analytics', childs: [] },
        // {
        //   title: t('onlineClasses'),
        //   icon: OnlineClassIcon,
        //   href: '/session',
        //   childs: [],
        // },
        // {
        //   title: t('package'),
        //   icon: packageIcon,
        //   href: '/subscribe',
        //   childs: [],
        // },
        {
            title: t('support'),
            icon: supportIcon,
            href: '/support',
            childs: [
                {
                    title: 'Check Your System',
                    icon: CheckingIcon,
                    href: 'check-your-system',
                },
                { title: 'Contact Us', icon: ContactUsIcon, href: 'contact-us' },
                { title: 'Leave Feedback', icon: FeedbackIcon, href: 'feedback' },
                { title: 'Submit Query', icon: HelpIcon, href: 'ticket' },
            ],
        },

        // {
        //   title: t('contactUs'),
        //   icon: contactIcon,
        //   href: '/support/contact-us',
        //   childs: [],
        // },
        // {
        //   title: t('notificationCenter'),
        //   icon: NotificationIcon,
        //   href: '/notifications',
        //   childs: [],
        // },
    ];
    const handleLogout = () => {
        dispatch(logoutAction());
        // router.push('/auth/login');
        window.location.href = '/auth/login';
    };

    const handleClick = (title) => {
        if (title === 'Practice') setOpenPractice(!openPractice);
        else if (title === 'Support') setOpenSupport(!openSupport);
        else if (title === 'Add On') setOpenAddOn(!openAddOn);

    };

    const isActiveRoute = (route) =>
        router.asPath === route || (route && router.asPath.startsWith(route));

    const isActiveSubRoute = (route) => router.asPath === route;

    useEffect(() => {
        // let isProgress = false
        dispatch(showProgress());
    }, [])
    const getIcon = (isOpen) => isOpen ? <ExpandLess /> : <ExpandMore />;

    return (
        <>
            <List
                component="nav"
                aria-labelledby="dashboard-sidebar"
                className={classes.list}>
                {dashboardSidebar.map((item) => {
                    const Icon = item.icon;
                    return (
                        <CustomLink
                            type={item.childs.length > 0 ? '' : 'Link'}
                            href={item.href}
                            key={item.title}>
                            <ListItem
                                className={clsx(classes.listItem, {
                                    [classes.listItemActive]: isActiveRoute(
                                        item.href,
                                    ),
                                })}
                                button
                                onClick={(e) => {
                                    handleClick(item.title);
                                    if (item.title === t('dashboard')) {
                                        dispatch(showProgress(false));
                                        if (toggleDrawer) {
                                            toggleDrawer(e, false);
                                        }
                                    }
                                }}

                                key={item.title}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <img src={item.icon} alt={item.title}
                                        style={{
                                            filter: isActiveRoute(item.href)
                                                ? 'brightness(1)'
                                                : 'brightness(0)'
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                    classes={{
                                        primary: clsx(classes.listItemText, {
                                            [classes.listItemTextActive]: isActiveRoute(
                                                item.href,
                                            ),
                                        }),
                                    }}
                                />

                                {item.title === 'Practice' && getIcon(openPractice)}
                                {item.title === 'Support' && getIcon(openSupport)}
                                {item.title === 'Add On' && getIcon(openAddOn)}
                            </ListItem>
                            {
                                item.childs?.length !== 0 && (
                                    <Collapse
                                        in={
                                            (() => {
                                                if (item.title === 'Practice') return openPractice;
                                                if (item.title === 'Support') return openSupport;
                                                if (item.title === 'Add On') return openAddOn;
                                            })() ||
                                            item.childs.some(child =>
                                                router.pathname ===
                                                (item.title === 'Practice'
                                                    ? `/practice/${child.href}`
                                                    : item.title === 'Add On'
                                                        ? `/${child.href}`
                                                        : `/support/${child.href}`
                                                )
                                            )
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {item.childs?.map((child) => (
                                                <Link
                                                    key={child.href}
                                                    href={
                                                        item.title === 'Practice'
                                                            ? `/practice/${child.href}`
                                                            : item.title === 'Add On'
                                                                ? `/${child.href}`
                                                                : `/support/${child.href}`
                                                    }
                                                >
                                                    <a className={classes.sidebarLink}>
                                                        <ListItem button className={classes.nested}>
                                                            <ListItemIcon className={classes.listItemIcon}>
                                                                {item.title !== 'Support' &&
                                                                    <img
                                                                        src={child.icon}
                                                                        alt={item.title}
                                                                        style={{
                                                                            filter: isActiveSubRoute(
                                                                                item.title === 'Practice'
                                                                                    ? `/practice/${child.href}`
                                                                                    : item.title === 'Add On'
                                                                                        ? `/${child.href}`
                                                                                        : `/support/${child.href}`,
                                                                            )
                                                                                ? 'brightness(1)'
                                                                                : 'brightness(0)',
                                                                        }}
                                                                    />}
                                                                {item.title === 'Support' &&
                                                                    <child.icon
                                                                        width={24}
                                                                        height={24}
                                                                        color={isActiveSubRoute(
                                                                            item.title === 'Support' && `/support/${child.href}`,
                                                                        )
                                                                            ? '#0F4C8F'
                                                                            : '#000'}
                                                                    />
                                                                }
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={child.title}
                                                                classes={{
                                                                    primary: clsx(classes.listItemText, {
                                                                        [classes.listItemTextActive]: isActiveSubRoute(
                                                                            item.title === 'Practice'
                                                                                ? `/practice/${child.href}`
                                                                                : item.title === 'Add On'
                                                                                    ? `/${child.href}`
                                                                                    : `/support/${child.href}`,
                                                                        ),
                                                                    }),
                                                                }}
                                                            />
                                                        </ListItem>
                                                    </a>
                                                </Link>
                                            ))}
                                        </List>

                                    </Collapse>
                                )
                            }
                        </CustomLink>
                    );
                })}
                <Hidden lgUp>
                    <ListItem
                        className={clsx(classes.listItem)}
                        button
                        onClick={handleLogout}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <img src={logoutIcon}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Logout"}
                            classes={{
                                primary: clsx(classes.listItemText,),
                            }}
                        />
                    </ListItem>
                </Hidden >
            </List >

        </>
    );
};

CommanMenuData.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    t: PropTypes.func.isRequired,
    includeTopMargin: PropTypes.bool,
    showSidebar: PropTypes.bool,
};

CommanMenuData.defaultProps = {
    includeTopMargin: true,
    showSidebar: true,
};

export default CommanMenuData;
