import * as React from 'react'

function SvgMockTest(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H2.00001L4.92901 19.071C3.99904 18.1434 3.26154 17.0413 2.75889 15.8278C2.25623 14.6143 1.99833 13.3135 2.00001 12ZM6.82801 20H12C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4003 4.00346 8.93854 4.60896C7.47673 5.21447 6.2273 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 14.152 4.85101 16.165 6.34301 17.657L7.75701 19.071L6.82801 20ZM11 6H13V18H11V6ZM7.00001 9H9.00001V15H7.00001V9ZM15 9H17V15H15V9Z"
                fill={props.color} />
        </svg>


    )
}

export default SvgMockTest
